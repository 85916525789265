import enquire from 'enquire.js'

import Component from '../core/Component'
import { queries } from '../core/config'
import Scroll from '../services/Scroll'

export default class Sandwich extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            image: null,
            svg: null,
        }

        this.enquireHandler = {
            match: ::this._match,
            unmatch: ::this._unmatch,
        }
    }

    prepare() {
        enquire.register(queries.xlargeUp, this.enquireHandler);
    }

    destroy() {
        enquire.unregister(queries.xlargeUp, this.enquireHandler);

    }

    updateSVGSize = () => {
        if (!this.ref.image || !this.ref.svg) return

        const imgRect = this.ref.image.getBoundingClientRect()

        let REF_IMAGE_WIDTH = 650

        if (this.options.imgWidth) {
            REF_IMAGE_WIDTH = this.options.imgWidth
        }

        const scale = imgRect.width / REF_IMAGE_WIDTH

        this.ref.svg.style.transform = `scale(${scale})`

        const listItems = this.element.querySelectorAll("li")
        listItems.forEach((li, index) => {
            if (index !== listItems.length - 1) {
                li.style.marginBottom = `${20 * Math.pow(scale, 3.7)}px`
            }
        })
    }

    _match() {
        this.updateSVGSize()
        Scroll.on("resize", this.updateSVGSize)
    }

    _unmatch() {
        Scroll.off("resize", this.updateSVGSize)
        this.element.querySelectorAll("li").forEach(li => {
            li.style = ""

        })
    }

}
