import Component from '../core/Component'
import { scrollToElement } from '../services/Scroll'
import EventBus from '../core/EventBus'

const STATES = {
    ACTIVE: 'is-active',
    READY: 'is-ready',
}

const EMITS = {
    ITEM_OPEN: 'open',
}

const defaults = {
    onOpen: () => {},
}

class AccordionItem extends Component {
    constructor(element, options = {}) {
        super(element, defaults)

        this.ref = {
            toggle: null,
            block: null,
        }

        this.options = options

        this.ref.toggle.addEventListener('click', this.handleClick)
        this.ref.block.addEventListener('transitionend', this.handleTransitionEnd)

        this.checkHash()
        if (this.initializedWithHash) {
            this.requestScrollToElement()
            this.open()
        } else if (this.element.classList.contains('is-active')) {
            this.open()
        }
    }

    handleClick = (event) => {
        event.preventDefault()

        if (this.is(STATES.ACTIVE)) {
            this.close()
        } else {
            this.requestScrollToElement()
            this.open()
        }
    }

    open() {
        if (this.requestedScroll && this.requestedScrollForced) {
            this.requestedScroll()
        }

        if (this.element.classList.contains(STATES.ACTIVE)) {
            return
        }
        this.element.classList.add(STATES.ACTIVE)
        this.ref.block.style.maxHeight = `${this.ref.block.scrollHeight}px`
        if (this.options.onOpen) {
            this.options.onOpen(this)
        }
    }

    requestScrollToElement(element = this.element, FORCED = false) {
        this.requestedScrollForced = FORCED

        this.requestedScroll = () => {
            scrollToElement(element, { mode: 'auto' })
            this.requestedScroll = null
            this.requestedScrollForced = false
        }
    }

    handleTransitionEnd = () => {
        if (this.requestedScroll) {
            this.requestedScroll()
        }

        EventBus.emit('dom:change', {})
    }

    close() {
        this.element.classList.remove(STATES.ACTIVE)
        this.ref.block.style.maxHeight = `0`

        if (this.options.onClose) {
            this.options.onClose(this)
        }
    }

    resize() {
        if (this.is(STATES.ACTIVE)) {
            this.ref.block.style.maxHeight = `${this.ref.block.scrollHeight}px`
        }
    }

    checkHash() {
        const url = window.location.href
        const id = url.substring(url.indexOf('#')).replace('#', '')

        if (id && id === this.ref.block.id) {
            this.initializedWithHash = true
        }
    }
}

export default class Accordion extends Component {
    constructor(element, options = {}) {
        super(element, {
            ...defaults,
            autoClose: true,
        })

        this.ref = {
            items: [],
        }

        this.options = options

        this.items = []
    }

    prepare() {
        this.items = this.ref.items.map(
            (item) =>
                new AccordionItem(item, {
                    onOpen: this.handleItemOpen,
                })
        )

        this.element.classList.add(STATES.READY)

        window.addEventListener('resize', this.resize)
    }

    destroy() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        this.items.forEach((item) => item.resize())
    }

    handleItemOpen = (currentItem) => {
        if (this.options.autoClose) {
            this.items.filter((item) => item !== currentItem).forEach((item) => item.close())
        }

        this.emit(EMITS.ITEM_OPEN, currentItem)
    }
}
